import Home from '@/components/Home.vue'
import KnowledgeBase from '@/components/KnowledgeBase.vue'
import KnowledgeBaseDetail from '@/components/KnowledgeBaseDetail.vue'
import Page from '@/components/Page.vue'
import Contact from '@/components/Contact.vue'
import SearchPage from '@/components/SearchPage.vue'
import News from '@/components/News.vue'
import NewsDetail from '@/components/NewsDetail.vue'
import Manufacturer from '@/components/Manufacturer.vue'
import DataSheet from '@/components/DataSheet.vue'
import DataSheetDetail from '@/components/DataSheetDetail.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/knowledge-base',
        name: 'KnowledgeBase',
        component: KnowledgeBase
    },
    {
        path: '/knowledge-base/:slug',
        name: 'KnowledgeBaseDetail',
        component: KnowledgeBaseDetail
    },
    {
        path: '/news',
        name: 'News',
        component: News
    },
    {
        path: '/news/:slug',
        name: 'NewsDetail',
        component: NewsDetail
    },
    {
        path: '/manufacturer/:slug',
        name: 'Manufacturer',
        component: Manufacturer
    },
    {
        path: '/technical-data-search/:manufacturer/:size',
        name: 'DataSheet',
        component: DataSheet
    },
    {
        path: '/technical-data-sheet/:slug',
        name: 'DataSheetDetail',
        component: DataSheetDetail
    },
    {
        path: '/search',
        name: 'SearchPage',
        component: SearchPage
    },
    {
        path: '/:slug', 
        name: 'Page', 
        component: Page
    }
];

export default routes;

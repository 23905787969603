<template>
   <VueSlickCarousel class="banner" v-bind="settings" v-if="banners.length" style="z-index:-1;">
      <div class="item" v-for="item in banners" :key="item.id">
        <img :src="item.image" class="w-100 img-fluid"/>
        <div class="hover-text">
            <h1 v-if="item.descriptiom != ''">{{item.description}}</h1>
            <div class="">
              <a v-if="item.page != ''" :href="`${item.page}`" class="btn btn-primary mt-2" tabindex="-1">Read more</a>
              <a v-else-if="item.knowledge_based != ''" :href="`/knowledge-base/${item.knowledge_based}`" class="btn btn-primary mt-2" tabindex="-1">Read more</a>
           </div>
        </div>
      </div>
   </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
   name: 'Slider',
   props: ['banners'],
   components: { VueSlickCarousel },
   data(){
      return {
         settings: {
           "accessibility": false,
           "dots": false,
           "infinite": true,
           "slidesToShow": 1,
           "slidesToScroll": 1,
           "adaptiveHeight": true,
           "autoplay": true,
         }
      }
   }
}
</script>
<template>
  <div>
    <Banner :title="record.title" :img="banner"/>
    <section class="pt-80">
      <div class="container-fluid"  v-html="record.description"></div>
    </section>
    </div>
</template>

<script>
import Banner from '@/partials/Banner'
import axios from 'axios'

export default {
  name: 'KnowledgeBaseDetail',
  mounted() {
    axios
      .get(process.env.VUE_APP_API_URL + 'knowledge-base/' + this.$route.params.slug)
      .then(response => {
        this.banner = response.data.banner;
        this.record = response.data.record;
        if(response.data.record.meta_schema != ''){
          this.ldJson = JSON.parse(response.data.record.meta_schema);
        }
      })
  },
  data (){
    return {
      banner:"",
      record:{},
      ldJson: {}
    }
  },
  components:{
    Banner
  },
  methods: {

  },
  metaInfo() {
    var metas = {
      title: this.record.meta_title,
      meta: [
          { 
            name: 'description', 
            content:  this.record.meta_description
          },
          { 
            name: 'keywords', 
            content:  this.record.meta_keywords
          }
      ]
    }

    if(this.ldJson){
      metas.script = [{
        vmid: "ldJson",
        type: 'application/ld+json',
        json: this.ldJson
      }];
    }

    return metas;
  },
}
</script>
<template>
  <section class="inner-banner" style="z-index:-1;">
     <img v-if="typeof img !== 'undefined' && img != '' && img != null" :src="`${img}`">
     <img v-else src="/assets/img/default-banner.jpg">
     <div class="hover-text">
        <div class="container">
           <div class="row">
              <div class="col-md-10 mx-auto">
                 <h1>{{title}}</h1>
              </div>
           </div>
        </div>
     </div>
  </section>
</template>

<script>
export default {
  name: 'Banner',
  props: ['title','img'],
}
</script>
<template>
  <footer>
   <div class="container-fluid">
      <div class="row mb-30">
         <div class="col-md-6 col-lg-4 mb-4 mb-lg-0" v-html="settings.AboutText"></div>
         <div class="col-md-6 col-lg-3 col-xl-3 mb-4 mb-lg-0 order-lg-4">
            <h3>Contact Details</h3>
            <ul class="noStyle contact-list">
               <li>
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <a :href="`tel:${settings.ContactNumber}`"> {{settings.ContactNumber}}</a>
               </li>
               <li>
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <a :href="`mailto:${settings.SiteEmail}`"> {{settings.SiteEmail}}</a>
               </li>
               <li>
                  <i class="fa fa-wpforms" aria-hidden="true"></i> 
                  <a href="/contact">Contact US</a>
               </li>
            </ul>
         </div>
         <div class="col-md-12 col-lg-6 col-xl-5">
            <div class="row">
               <div class="col-md-6 col-lg-6 mb-4 mb-lg-0 ">
                  <h3>Quick Links</h3>
                  <ul class="noStyle">
                     <li><a href="/">Home</a></li>
                     <li><a href="/diesel-generators">Diesel Generators</a></li>
                     <li><a href="/ups-maintenance-and-service">Services</a></li>
                     <li><a href="/ups-power-systems">UPS Power Systems</a></li>
                     <li><a href="/rotary-ups">Rotary UPS</a></li>
                     <li><a href="/data-centre-design-and-build">Data Centre</a></li>
                     <li><a href="/knowledge-base">Knowledge Base</a></li>
                     <li><a href="/news">News</a></li>
                  </ul>
               </div>
               <div class="col-md-6 col-lg-6 mb-4 mb-lg-0 ">
                  <h3>Quick Links</h3>
                  <ul class="noStyle">
                     <li><a href="/generator-maintenance">Generator Maintenance</a></li>
                     <li><a href="/fuel-polishing"> Generator Fuel Polishing</a></li>
                     <li><a href="/load-bank-test">Generator Load Testing</a></li>
                     <li><a href="/ups-maintenance">UPS Maintenance</a></li>
                     <li><a href="/ups-batteries"> UPS Battery Replacement</a></li>
                     <li><a href="/load-bank-test"> UPS Load Testing</a></li>
                     <li><a href="/emergency-callout"> Emergency Technician Callout</a></li>
                     <li><a href="/gallery"> Gallery</a></li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="copyright mt-5">
      <div class="container-fluid">
         <div class="row">
            <div class="col-lg-6">{{settings.CopyRight}}</div>
            <div class="col-lg-6 text-lg-right">
               <ul class="noStyle">
                  <li><a href="/sitemap.xml">Sitemap </a></li>
                  <li><a href="/terms-and-conditions">Terms & Conditions</a></li>
                  <li><a href="/contact">Contact</a></li>
                  <li><a href="/privacy">Privacy </a></li>
                  <li><a href="/about">About</a></li>
                  <li><a href="/help">Help</a></li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</footer>
</template>

<script>
export default {
  name: 'Footer',
  props:['menus','settings']
}
</script>
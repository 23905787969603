<template>
 <div class="cont bg-light">
    <h6 class="title">We’re Here To Help</h6>
    <div class="row mx-0 no-gutters">
       <div class="col-md-6 align-items-center">
          <div class="w-100">
             <div class="numb">
               <a :href="`tel:${settings.PhoneNumber}`">
                  <i class="fa fa-phone" aria-hidden="true"></i> {{settings.PhoneNumber}}
               </a>
             </div>
             <div class="conttBt"><i class="fa fa-envelope" aria-hidden="true"></i> <a href="/contact">CONTACT US</a></div>
             <div class="kldgeBt" v-if="this.$route.name != 'Home'">
               <a href="/knowledge-base"><i class="fa fa-search" aria-hidden="true"></i> SEARCH TECHNICAL KNOWLEDGE BASE</a>
             </div>
          </div>
       </div>
       <div class="col-md-6">
          <figure class="mb-lg-5 text-center"> <img src="/assets/img/img-3.png"></figure>
       </div>
    </div>
    <h6 class="title" v-if="this.$route.name == 'Home'">Search Our Free Technical Knowledge Base</h6>
    <div class="row mx-0" v-if="this.$route.name == 'Home'">
       <div class="col-md-8 col-lg-6 col-xl-4 mx-auto">
          <form action="/knowledge-base" method="get" class="mx-auto">
             <div class="input-group mb-3 searchitem">
                <input type="text" name="query" class="form-control" placeholder="Search">
                <i class="fa fa-search" aria-hidden="true"></i>
                <div class="input-group-append">
                   <button class="btn btn-success" type="submit">Search</button>
                </div>
             </div>
          </form>
       </div>
    </div>
 </div>
</template>

<script>
export default {
   name: 'HelpSection',
   props: ['settings'],
}
</script>
<template>
  <div>
    <Banner :title="page.title" :img="banner"/>
    <div class="container-fluid">
      <div class="row mt-40">
         <div class="col-md-8 col-lg-6 col-xl-4 mx-auto">
            <form action="/knowledge-base" method="get">
               <div class="input-group mb-3 searchitem">
                  <input type="text" name="query" class="form-control" placeholder="Search" v-model="this.$route.query.query">
                  <i class="fa fa-search" aria-hidden="true"></i>
                  <div class="input-group-append">
                     <button class="btn btn-success" type="submit">Search</button>
                  </div>
               </div>
            </form>
         </div>
      </div>
      <div class="row mt-40">
        <div class="col-md-6 col-lg-4 col-xl-4" v-for="item in records" :key="item.id">
          <div class="card kb-box">
             <div class="card-header">{{item.title}}</div>
             <div class="card-body">
                {{item.short_description}}
                <p class="text-right mb-0 mt-4">
                  <a class="btn btn-primary" :href="`/knowledge-base/${item.url}`">
                    Learn more <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                </p>
             </div>
          </div>
        </div>
        <div class="col-12" v-if="records.length">
          <pagination v-model="currentPage" :options="options" :records="totalPage" :per-page="perPage" @paginate="getData"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/partials/Banner'
import axios from 'axios'

export default {
  name: 'KnowledgeBase',
  mounted() {
    this.getData(this.currentPage)
  },
  data (){
    return {
      page:{},
      banner:"",
      records:[],
      currentPage:1,
      totalPage:1,
      perPage:15,
      options:{theme:"bootstrap4"},
      ldJson: {}
    }
  },
  components:{
    Banner
  },
  methods: {
    getData : function(pageNum){
      if(this.currentPage <= this.totalPage){
        axios
        .get(process.env.VUE_APP_API_URL + 'knowledge-base?page='+pageNum+'&query='+this.$route.query.query)
        .then(response => {
          this.page = response.data.page;
          this.banner = response.data.banner;
          this.records = response.data.records.data;
          this.currentPage = response.data.records.current_page;
          this.totalPage = response.data.records.total;
          this.perPage = response.data.records.per_page;
          if(response.data.page.meta_schema != ''){
            this.ldJson = JSON.parse(response.data.page.meta_schema);
          }
        })
      }
    }
  },
  metaInfo() {
    var metas = {
      title: this.page.meta_title,
      meta: [
          { 
            name: 'description', 
            content:  this.page.meta_description
          },
          { 
            name: 'keywords', 
            content:  this.page.meta_keywords
          }
      ]
    }
    
    if(this.ldJson){
      metas.script = [{
        vmid: "ldJson",
        type: 'application/ld+json',
        json: this.ldJson
      }];
    }

    return metas;
  },
}
</script>
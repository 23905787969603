<template>
   <div>
      <h3 class="title text-center mt-5">Our Power Continuity<sup>&reg;</sup> Solutions</h3>
      <div class="row">
        <div class="col-md-6 col-lg-4 col-xl-3" v-for="item in grids" :key="item.id">
           <a :href="`${item.url}`" class="box">
              <figure class="noMrgn">
               <img :src="item.image">
              </figure>
              <p>{{item.title}}</p>
           </a>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Grid',
  props:['grids']
}
</script>
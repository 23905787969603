<template>
   <div>
    <div class="survy2 my-5 d-none d-md-block">
        <figure><img src="/assets/img/banner-3.jpg"></figure>
        <div class="d-flex survyLink">
           <div class="flex-fill"><a href="/contact">Free Site Survey</a></div>
           <div class="flex-fill"><a href="/contact">Ask for a Quote</a></div>
           <div class="flex-fill"><a href="/contact">Technical Help</a></div>
        </div>
     </div>
     <div class="container-fluid">
        <div class="row d-block d-md-none">
           <div class="col-md-4 survy my-1">
              <a href="/contact"><p>Free Site Survey</p></a>
           </div>
           <div class="col-md-4 survy my-1">
              <a href="/contact"><p>Ask for a Quote</p></a>
           </div>
           <div class="col-md-4 survy my-1">
              <a href="/contact"><p>Technical Help</p></a>
           </div>
        </div>
     </div>
      <div class="down2">
         <div class="container-fluid px-0">
            <div class="row no-gutters mx-0">
               <div class="col-lg-5 d-flex align-items-stretch">
                  <figure>
                     <a href="/business-continuity-test">
                        <img src="/assets/img/grap.png">
                     </a>
                  </figure>
               </div>
               <div class="col-lg-7">
                  <div class="download p-5">
                     <div class="row">
                        <div class="col-lg-5 col-xl-4 herobaner d-none d-xl-block pl-5"><img src="/assets/img/hero-app-banner_en.png"></div>
                        <div class="col-lg-12 col-xl-8 text-center">
                           <h6>Download our Free Power <br>Calculator App</h6>
                           <a :href="`${settings.androidAppLink}`" class="mx-2">
                              <img src="/assets/img/andriod.svg">
                           </a>
                           <a :href="`${settings.iosAppLink}`" class="mx-2">
                              <img src="/assets/img/ios.svg">
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="container-fluid">
        <div class="text-center py-5"><img src="/assets/img/power-ISO.jpg"></div>
     </div>
   </div>
</template>

<script>
export default {
  name: 'Slider',
  props: ['settings'],
}
</script>
import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueRouter from "vue-router";
import Vuelidate from 'vuelidate';
import Toasted from 'vue-toasted';
import Pagination from 'vue-pagination-2';
import App from './App.vue'
import Router from './router'

Vue.config.productionTip = false

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(Toasted, {
  position: 'top-left',
  duration: 5000,
  keepOnHover: true,
  iconPack: "fontawesome"
});
Vue.component('pagination', Pagination);

const router = new VueRouter({
    routes: Router,
    mode:'history'
})

new Vue({
  el: '#app',
  router: router,
  render: h => h(App),
})

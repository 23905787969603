<template>
  <div>
    <Banner :title="page.title" :img="banner"/>
    <section class="pt-80">
      <div class="container-fluid"  v-html="page.description"></div>
    </section>
    <div class="container" v-if="manufacturers.length">
      <ManufacturerLogo :manufacturers="manufacturers"/>
      <DataSheetSelection :manufacturers="manufacturers" :sizes="sizes" :show_search="show_search"/>
    </div>
  </div>
</template>

<script>
import Banner from '@/partials/Banner'
import ManufacturerLogo from '@/partials/ManufacturerLogo'
import DataSheetSelection from '@/partials/DataSheetSelection'
import axios from 'axios'

export default {
  name: 'Page',
  mounted() {
    axios
      .get(process.env.VUE_APP_API_URL + 'page/' + this.$route.params.slug)
      .then(response => {
        this.page = response.data.page;
        this.manufacturers = response.data.manufacturers;
        this.sizes = response.data.sizes;
        this.show_search = response.data.show_search;
        this.banner = response.data.banner;
        if(response.data.page.meta_schema != ''){
          this.ldJson = JSON.parse(response.data.page.meta_schema);
        }
      })
  },
  data (){
    return {
      page:{},
      manufacturers:{},
      sizes:{},
      show_search:false,
      banner:"",
      ldJson: {}
    }
  },
  components:{
    Banner,
    ManufacturerLogo,
    DataSheetSelection
  },
  methods: {

  },
  metaInfo() {
    var metas = {
      title: this.page.meta_title,
      meta: [
          { 
            name: 'description', 
            content:  this.page.meta_description
          },
          { 
            name: 'keywords', 
            content:  this.page.meta_keywords
          }
      ]
    }

    if(this.ldJson){
      metas.script = [{
        vmid: "ldJson",
        type: 'application/ld+json',
        json: this.ldJson
      }];
    }

    return metas;
  },
}
</script>
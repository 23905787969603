<template>
  <div>
    <Banner :title="title" :img="banner"/>
    <div class="container-fluid">
      <div class="row mt-30 mb-30" v-if="records.length > 0">
          <div class="col-12">
            <h2>Find Our DataSheets</h2>
          </div>
          <div class="col-md-4 mb-10" v-for="item in records" :key="item.id">
            <a class="btn btn-info w-100 text-left" :href="`/technical-data-sheet/${item.url}`">{{item.title}}</a>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/partials/Banner'
import axios from 'axios'

export default {
  name: 'DataSheet',
  mounted() {
    this.manufacturer = this.$route.params.manufacturer
    this.size = this.$route.params.size
    
    if(this.manufacturer == '' || this.size == ''){
      window.location.href = '/';
    }

    axios
      .post(process.env.VUE_APP_API_URL + 'data-sheets', {manufacture:this.manufacturer, size:this.size})
      .then(response => (this.records = response.data.records, this.title = response.data.title))
  },
  data (){
    return {
      title:"",
      banner:"",
      records:[],
      manufacturer:"",
      size:"",
      resultCount:0
    }
  },
  components:{
    Banner,
  },
  methods: {

  },
}
</script>
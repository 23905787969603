<template>
   <nav class="navbar navbar-expand-xl">
      <div class="container-fluid">
            <a class="navbar-brand" href="/"><img src="/assets/img/logo.jpg"></a>
            
            <div class="navbar-text searchBox">
               <a class="d-sm-block d-lg-none headerphone" :href="`tel:${settings.PhoneNumber}`"><i class="fa fa-phone" aria-hidden="true"></i> {{settings.PhoneNumber}}</a>

               <a class="d-sm-block d-lg-none btn mr-2 btn-group headersearch" href="#">
                  <i class="fa fa-search dropdown-toggle" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                  <div class="dropdown-menu mx-0 my-0 py-0">
                   <form action="/search" method="get">
                     <input type="text" name="query" class="form-control" placeholder="Search here..."/>
                   </form>
                  </div>
               </a>
            </div>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
               <span class="navbar-toggler-icon"></span>
            </button>

         <div class="collapse navbar-collapse" id="navbarText">
           <ul class="main-menu navbar-nav mx-auto">
              <li class="nav-item dropdown" v-for="item in menus" :key="item.id">
                  
                  <a class="nav-link dropdown-toggle" v-if="item.url == '#'" :href="`#`" :id="`navbarDropdown${item.id}`" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{item.title}}
                    <span class="arrow" v-if="item.children.length > 0"><i class="fa fa-angle-down" aria-hidden="true"></i></span>
                  </a>
                  
                  <a class="nav-link" v-else-if="item.url == '/'" href="/">
                    {{item.title}}
                    <span class="arrow" v-if="item.children.length > 0"><i class="fa fa-angle-down" aria-hidden="true"></i></span>
                  </a>

                  <a class="nav-link" v-else :href="`/${item.url}`">
                    {{item.title}}
                    <span class="arrow" v-if="item.children.length > 0"><i class="fa fa-angle-down" aria-hidden="true"></i></span>
                  </a>

                 <div class="dropdown-menu dropmenu" v-if="item.children.length > 0" :aria-labelledby="`navbarDropdown${item.id}`">
                    <div class="container">
                       <div class="row">
                          <div class="col-lg-3 col-md-4 mb-3" v-for="child in item.children" :key="child.id">
                             <ul>
                                 <li>
                                    <a v-if="child.url == '#'" :href="`#`"><strong>{{child.title}}</strong></a>
                                    <a v-else :href="`/${child.url}`"><strong>{{child.title}}</strong></a>
                                 </li>
                                 <li v-for="link in child.children" :key="link.id" class="sub-child">
                                    <a v-if="link.url == '#'" :href="`#`">{{link.title}}</a>
                                    <a v-else :href="`/${link.url}`">{{link.title}}</a>
                                 </li>
                             </ul>
                          </div>
                       </div>
                       <div class="row">
                          <div class="col-lg-8 offset-lg-4 col-md-12 text-md-right">
                             <a class="btn btn-contact py-2" href="/contact"><i class="fa fa-phone" aria-hidden="true"></i> Contact us</a>
                             <a class="btn btn-contact py-2 ml-3" href="/knowledge-base"><i class="fa fa-lightbulb-o" aria-hidden="true"></i> Knowledge Base</a>
                          </div>
                       </div>
                    </div>
                 </div>
              </li>
           </ul>
         </div>

         <div class="navbar-text searchBox d-none d-lg-block">
               <a class="btn mr-2 btn-group" href="#">
                  <i class="fa fa-search dropdown-toggle" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                  <div class="dropdown-menu mx-0 my-0 py-0">
                   <form action="/search" method="get">
                     <input type="text" name="query" class="form-control" placeholder="Search here..."/>
                   </form>
                  </div>
               </a>
              <a class="btn btn-contact" :href="`tel:${settings.PhoneNumber}`"><i class="fa fa-phone" aria-hidden="true"></i> {{settings.PhoneNumber}}</a>
         </div>

      </div>
   </nav>
</template>

<script>
export default {
  name: 'Header',
  props: ['menus','settings'],
}
</script>
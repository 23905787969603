<template>
  <div>
    <Header :menus="menus" :settings="settings" v-if="notFound == 1"/>
    <div id="app"><router-view></router-view></div>
    <HelpSection :settings="settings" v-if="show_help_grid == 1 && notFound == 1"/>
    <div class="container-fluid" v-if="show_help_grid == 1 && notFound == 1">
      <Grid :grids="grids"/>
    </div>
    <DownloadAppSection :settings="settings" v-if="notFound == 1"/>
    <Footer :menus="menus" :settings="settings" v-if="notFound == 1"/>
  </div>
</template>

<script>
import axios from 'axios'
import Header from '@/partials/Header'
import HelpSection from '@/partials/HelpSection'
import Grid from '@/partials/Grid'
import DownloadAppSection from '@/partials/DownloadAppSection'
import Footer from '@/partials/Footer'

export default {
  name: 'App',
  mounted() {
    axios
      .get(process.env.VUE_APP_API_URL + 'components')
      .then(response => (this.menus = response.data.menus, this.grids = response.data.grids, this.settings = response.data.settings))
  },
  data (){
    return {
      menus:[],
      grids:[],
      settings:{},
      show_help_grid: (this.$route.name == "Home" || this.$route.name == "Page"  || this.$route.name == "Contact") ? 1 : 0,
      notFound: (this.$route.name == "PageNotFound") ? 0 : 1
    }
  },
  components:{
    Header,
    HelpSection,
    Grid,
    DownloadAppSection,
    Footer
  },
  metaInfo() {
    return {
      link: [
        {rel: 'canonical', href: this.$route.path}
      ]
    }
  },
}
</script>
<template>
  <div>
    <Banner :title="page.title" :img="banner"/>
    <div class="contactus">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="form-section">
              <h2>Contact Us Form</h2>
              <form @submit.prevent="handleSubmit" class="mt-2">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="first_name">FIRST NAME<span>*</span></label>
                      <input type="text" name="first_name" class="form-control" id="contactFirstName" v-model="contactForm.first_name">
                      <div v-if="submitted && !$v.contactForm.first_name.required" class="invalid error">First Name is required</div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="last_name">LAST NAME<span>*</span></label>
                      <input type="text" name="last_name" class="form-control" id="contactLastName" v-model="contactForm.last_name">
                      <div v-if="submitted && !$v.contactForm.last_name.required" class="invalid error">Last Name is required</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="company">COMPANY NAME</label>
                      <input type="text" name="company" class="form-control" id="contactCompany" v-model="contactForm.company">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="email">EMAIL<span>*</span></label>
                      <input type="text" name="email" class="form-control" id="contactEmail" v-model="contactForm.email">
                      <div v-if="submitted && $v.contactForm.email.$error" class="invalid error">
                          <span v-if="!$v.contactForm.email.required">Email is required</span>
                          <span v-if="!$v.contactForm.email.email">Email is invalid</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="phone">PHONE<span>*</span></label>
                      <input type="text" name="phone" class="form-control" id="contactPhone" v-model="contactForm.phone">
                      <div v-if="submitted && $v.contactForm.phone.$error" class="invalid error">
                          <span v-if="!$v.contactForm.phone.required">Phone number is required</span>
                          <span v-if="!$v.contactForm.phone.numeric">Phone number must be valid number</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="topic">TOPIC</label>
                      <input type="text" name="topic" class="form-control" id="contactSubject" v-model="contactForm.topic">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <hr>
                    <h4>YOUR ENQUIRY</h4>
                      <label for="name">ENQUIRY MESSAGE<span>*</span></label>
                      <textarea name="message" class="form-control" id="contactMessage" v-model="contactForm.message" cols="30" rows="8"></textarea>
                      <div v-if="submitted && !$v.contactForm.message.required" class="invalid error">Message is required</div>
                      <label></label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 d-inline-flex">
                    <vue-recaptcha
                      ref="recaptcha"
                      @verify="onVerify"
                      @expired="onExpired"
                      sitekey="6Lf6UK4bAAAAAACNMAcqfehSJchGlnp4inIjP_ws">
                    </vue-recaptcha>
                    <button @click="resetRecaptcha" class="btn btn-primary"> <i class="fa fa-refresh" aria-hidden="true"></i> </button>
                  </div>
                </div>
                <div class="mt-3 mb-5">
                  <button type="submit" class="btn themeBtn">Submit</button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-6 address">
              <div v-html="page.description"></div>
              <div class="mapbox">
                <iframe src="https://www.google.com/maps/d/embed?mid=1nEnO-WFQHcogl1cXenSf-SAuuTBIQHep&hl=en" width="100%" height="650" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
          </div>
        </div>
        
        <div v-html="contactPart"></div>
      </div>
    </div>  
  </div>
</template>

<script>
import Banner from '@/partials/Banner'
import axios from 'axios'
import { required, email, numeric} from "vuelidate/lib/validators";
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'Contact',
  mounted() {
    axios
    .get(process.env.VUE_APP_API_URL + 'page' + this.$route.path)
    .then(response => {
      this.page = response.data.page;
      this.banner = response.data.banner;
      if(response.data.page.meta_schema != ''){
        this.ldJson = JSON.parse(response.data.page.meta_schema);
      }
    });

    axios
    .get(process.env.VUE_APP_API_URL + 'page/contact-part')
    .then(response => {
      this.contactPart = response.data.page.description;
    });
  },
  data (){
    return {
      page:{},
      banner:"",
      contactForm:{
        first_name:"",
        last_name:"",
        company:"",
        email:"",
        phone:"",
        topic:"",
        message:""
      },
      submitted: false,
      captcha: false,
      ldJson: {},
      contactPart:""
    }
  },
  components:{
    Banner,
    VueRecaptcha
  },
  validations: {
      contactForm: {
          first_name: { required },
          last_name: { required },
          email: { required, email },
          phone: { required, numeric },
          message: { required },
      }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.submitted = true;

      this.$v.$touch();
      
      if (this.$v.$invalid) {
        this.$toasted.error("You have some validation errors to resolve");
        return;
      }
      
      if(this.captcha){
        let formData = {
          first_name:this.contactForm.first_name,
          last_name:this.contactForm.last_name,
          company:this.contactForm.company,
          email:this.contactForm.email,
          phone:this.contactForm.phone,
          topic:this.contactForm.topic,
          message:this.contactForm.message,
        };

        this.contactForm.first_name = '';
        this.contactForm.last_name = '';
        this.contactForm.company = '';
        this.contactForm.email = '';
        this.contactForm.phone = '';
        this.contactForm.topic = '';
        this.contactForm.message = '';
        this.submitted = false;
        this.captcha = false;
        this.$toasted.success("We received your concern and we will get back to you shortly.");
        this.$refs.recaptcha.reset();

        axios.post(process.env.VUE_APP_API_URL + 'contact', formData)
        .then(response => {
          if(response.data.status != "success"){
            this.$toasted.error(response.data.msg);
          }
        });
      }else{
        this.$toasted.error("Please accept captcha");
        return;
      }
    },
    onVerify: function () {
      this.captcha = true;
    },
    onExpired: function () {
      this.captcha = false;
      this.$refs.recaptcha.reset();
    },
    resetRecaptcha() {
      this.captcha = false;
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
  },
  metaInfo() {
    var metas = {
      title: this.page.meta_title,
      meta: [
          { 
            name: 'description', 
            content:  this.page.meta_description
          },
          { 
            name: 'keywords', 
            content:  this.page.meta_keywords
          }
      ]
    }

    if(this.ldJson){
      metas.script = [{
        vmid: "ldJson",
        type: 'application/ld+json',
        json: this.ldJson
      }];
    }

    return metas;
  },
}
</script>
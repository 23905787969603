<template>
  <div>
    <Banner :title="title" :img="banner"/>
    <div class="container-fluid">
      <div class="row mt-30 mb-30" v-if="pages.length > 0">
          <div class="col-12">
            <h2>Find Our Articles</h2>
          </div>
          <div class="col-md-12 mb-10" v-for="item in pages" :key="item.id">
            <a class="btn btn-info w-100 text-left" :href="`/${item.url}`">{{item.title}}</a>
          </div>
      </div>
      <div class="row mb-30" v-if="knowledge_based.length > 0">
          <div class="col-12">
            <h2>Find Our Knowledge Base</h2>
          </div>
          <div class="col-md-12 mb-10" v-for="item in knowledge_based" :key="item.id">
            <a class="btn btn-success w-100 text-left" :href="`/knowledge-base/${item.url}`">{{item.title}}</a>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/partials/Banner'
import axios from 'axios'

export default {
  name: 'SearchPage',
  mounted() {
    this.query = this.$route.query.query
    if(this.query == ''){
      window.location.href = '/';
    }
    axios
      .post(process.env.VUE_APP_API_URL + 'search', {query:this.$route.query.query})
      .then(response => (this.pages = response.data.pages, this.knowledge_based = response.data.knowledge_based, this.title = parseInt(response.data.knowledge_based.length + response.data.pages.length) + ' records matched for : ' + this.query))
  },
  data (){
  return {
  title:"",
  banner:"",
  pages:[],
  knowledge_based:[],
  query:"",
  resultCount:0
  }
  },
  components:{
    Banner
  },
  methods: {

  },
}
</script>
<template>
   <div class="row mx-0 mt-1 mb-5" v-if="show_search && manufacturers.length">
      <div class="col-lg-3 mt-2 align-middle ds-search-label text-center">
         Search Technical Data Sheets
      </div>
      <div class="col-lg-3 mt-2">
         <select v-model="manufacturer" class="form-control">
            <option value="" selected>Select Manufacturer</option>
            <option v-for="item in manufacturers" :key="item.id" v-bind:value="item.url">{{item.name}}</option>
         </select>
      </div>
      <div class="col-lg-3 mt-2">
         <select v-model="size" class="form-control">
            <option value="" selected>Select Size</option>
            <option v-for="item in sizes" :key="item.id" v-bind:value="item.name">{{item.name}}</option>
         </select>
      </div>
      <div class="col-lg-3 mt-2 searchitem">
             <button class="btn btn-success btn-block" type="button" v-on:click="goDataSheet" >Find Data Sheet</button>
      </div>
   </div>
</template>
<script>
export default {
   name: 'DataSheetSelection',
   props:['manufacturers','sizes', 'show_search'],
   data () {
      return {
         manufacturer : "",
         size : "",
      }
   },
   methods:{
      goDataSheet:function(){
         if(this.manufacturer == '' && this.size == ''){
            this.$toasted.error("Please select Manufacturer and Size");
         }else if(this.manufacturer == ''){
            this.$toasted.error("Please select Manufacturer");
         }else if(this.size == ''){
            this.$toasted.error("Please select Size");
         }else{
            window.location.href= "/technical-data-search/" + this.manufacturer + "/" + this.size;
         }
      }
   }
}
</script>
<template>
   <div class="row">
      <div class="col-md-12 mt-5 mb-5" >
         <carousel :autoplay="true" :nav="false" :margin="30" :loop="true" :dots="false" :autoplayTimeout="4000" :autoplayHoverPause="true" :smartSpeed='450' :items="8" :responsive = "{0:{items:2},576:{items:4},768:{items:5},1200:{items:7},1500:{items:8}}" :autoWidth="true">
            <a :href="`/manufacturer/${item.url}`" class="box" v-for="item in manufacturers" :key="item.id">
               <img :src="item.image" alt="`${item.name}`">
            </a>
         </carousel>
      </div>
   </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
export default {
  name: 'ManufacturerLogo',
  props:['manufacturers'],
  components: { carousel },
}
</script>